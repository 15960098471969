import {
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subject } from 'rxjs';
import { QuizPopupComponent } from 'src/app/students/admission/quiz-popup/quiz-popup.component';
import { environment } from 'src/environments/environment';
import { AdminService } from '../../admin.service';
import { CommonServiceService } from '../../common-service.service';
import { VimeoService } from '../../vimeo.service';
import { SharableLinkComponent } from '../sharable-link/sharable-link.component';

@Component({
  selector: 'app-media-details',
  templateUrl: './media-details.component.html',
  styleUrls: ['./media-details.component.scss'],
})
export class MediaDetailsComponent {
  @ViewChild('videoItemsContainer') videoItemsContainer: ElementRef;
  // String type
  @HostBinding('class') classes: string =
    'd-flex h-100 overflow-hidden p-0 p-xl-1';

  @HostListener('window:resize', ['$event']) checkDeviceType(): void {
    const isMobile = window.innerWidth <= 1024;
    this.deviceType = isMobile ? 'Mobile' : 'Desktop';
    if (this.deviceType === 'Mobile') {
      this.sharePopupPosition = 'bottom';
    } else {
      this.sharePopupPosition = 'center';
    }
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    clearTimeout(this.scrollTimeout);

    this.scrollTimeout = setTimeout(() => {
      const container = this.videoItemsContainer.nativeElement;
      const scrollPercentage =
        (container.scrollTop + container.clientHeight) / container.scrollHeight;
      if (scrollPercentage >= 0.8) {
        this.loadMoreData();
      }
    }, 300);
  }

  private scrollTimeout: any;
  imageBaseURL: string = environment.apiUrl;
  baseURL: string = environment.apiUrl;
  searchText: string;
  mediaDetailsPath: string;
  // Number type
  channelId: number;
  videoId: number;
  categoryId: number;

  // Boolean type
  videoLoader: boolean;
  islessonType: boolean;
  isQuizAvailable: boolean;
  isVideosPaginated: boolean = true;

  // Object type
  videoNotes: any;
  videoDetail: any;
  videoList: any[] = [];
  videoParams: {
    page?: number;
    pageSize?: number;
    q?: string;
    countryShortCode?: string;
    category?: number;
  } = {
    page: 1,
    pageSize: 10,
    q: '',
    countryShortCode: this.router.url.includes('US') ? 'US' : 'UK',
  };

  // Mixed type
  viewer: any = 'google';
  videoForLoad: any;
  ref: DynamicDialogRef;
  sharePopupPosition: string;
  deviceType: string;
  localS3URL: string;
  isUserLoggedIn: boolean;
  isStudent: boolean;
  // Subject type
  searchTextChanges: Subject<string> = new Subject<string>();
  publicOrPrivatePathForCategory: string = 'admin/categories/all';
  publicOrPrivatePathForVideos: string = 'users/videos';
  constructor(
    private activatedRouter: ActivatedRoute,
    private router: Router,
    private commonService: CommonServiceService,
    private sanitizer: DomSanitizer,
    public adminService: AdminService,
    private vimeoService: VimeoService,
    public dialogService: DialogService,
    private renderer: Renderer2
  ) {
    this.checkDeviceType();
  }

  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.isUserLoggedIn = localStorage.getItem('user') ? true : false;
    const userDetails = JSON.parse(localStorage.getItem('user')) || {};
    this.isStudent = userDetails?.roleId == 2 ? true : false;
    this.publicOrPrivatePathForCategory =
      this.isUserLoggedIn && this.isStudent
        ? 'admin/categories/all'
        : 'admin/categories/public';
    this.publicOrPrivatePathForVideos =
      this.isUserLoggedIn && this.isStudent
        ? 'users/videos'
        : 'users/videos/public';
    this.activatedRouter.queryParams.subscribe(
      (res) => {
        if (res['id']) {
          this.categoryId = +res['id'];
        }
        if (res['videoId']) {
          this.videoId = +res['videoId'];
          console.log('thiss is shared video', this.isUserLoggedIn);
        }
        this.videoLoader = true;
        this.getVideoDetails();
        this.getVideoList();
      },
      (error) => {
        console.log('error', error);
      }
    );
  }

  getVideoDetails() {
    this.commonService
      .getByIdDetail(
        this.isUserLoggedIn ? 'users/video/' : 'users/videos/public/',
        this.videoId
      )
      .subscribe(
        (res) => {
          this.videoDetail = res.data;
          this.videoDetail['videoUrl'] = this.videoDetail?.videoUrl
            ? this.videoDetail?.videoUrl.replace('watch?v=', 'embed/')
            : null;
          if (this.videoDetail.videoTypes == 'reading') {
            this.islessonType = true;
          } else {
            this.islessonType = false;
          }
          if (this.videoDetail.channel == 1) {
            this.localS3URL = `${this.imageBaseURL}common/getfile/${this.videoDetail.videoUrl}`;
            this.videoLoader = false;
          }
          this.videoNotes = this.videoDetail.notes;
          if (this.videoDetail.channel == 2) {
            this.videoLoader = false;
          }
          if (this.videoDetail.channel == 3 && !this.islessonType) {
            this.getVimeoChannelIds();
          }
          this.isQuizAvailable = this.videoDetail.questions;
          this.baseURL =
            this.videoDetail?.notes && environment.apiUrl + this.videoNotes;
        },
        (error) => {
          this.commonService.showToast('Something went wrong', 'warn', 3000);
          console.log(`Error at ${this.constructor.name} :- ${error}`);
        }
      );
  }

  navigateToBack() {
    const backRoutePath: string = this.isUserLoggedIn
      ? this.isStudent
        ? `student/admission/${
            this.router.url.includes('US') ? 'US' : 'UK'
          }/tipsAndAdvice`
        : `organisation/app-shell/${
            this.router.url.includes('US') ? 'US' : 'UK'
          }`
      : `public/student-videos/${this.router.url.includes('US') ? 'US' : 'UK'}`;
    this.router.navigate([backRoutePath]);
  }

  share() {
    if (navigator.share) {
      navigator
        .share({
          title: 'AlgoEd Student | Insider Strategies',
          url: `${environment.openShareLink}public/video/${
            this.router.url.includes('US') ? 'US' : 'UK'
          }?videoId=${this.videoId}`,
        })
        .catch((err) => {
          console.error('Error sharing:', err);
        });
    } else {
      this.ref = this.dialogService.open(SharableLinkComponent, {
        header: 'Share',
        height: '320px',
        width: '100%',
        style: { maxWidth: '600px' },
        position: this.sharePopupPosition,
        contentStyle: {
          overflow: 'auto',
        },
        baseZIndex: 10000,
        data: {
          // video: { slug: this.videoDetail.slug },
          isDirectURL: true,
          video: `${environment.openShareLink}public/video/${
            this.router.url.includes('US') ? 'US' : 'UK'
          }?videoId=${this.videoId}`,
        },
      });
    }
  }

  getVideoList() {
    this.commonService
      .getList(this.publicOrPrivatePathForVideos, this.videoParams)
      .subscribe(
        (res) => {
          this.videoList = [];
          this.videoList.push(...res.data.videos);
          console.log('this.videoList', this.videoList);

          if (res.data.videos.length == 0) {
            this.isVideosPaginated = false;
          }
        },
        (error) => {
          this.commonService.showToast('Something went wrong', 'warn', 3000);
          console.log(`Error at ${this.constructor.name} :- ${error}`);
        }
      );
  }

  getVimeoChannelIds() {
    this.vimeoService.getVimeoChannelIds({ data: { id: 3 } }).subscribe(
      (item) => {
        this.channelId = item.data[0].vimeoChannelId;
        this.getVideoIframe();
      },
      (error) => {
        this.commonService.showToast('Something went wrong', 'warn', 3000);
      }
    );
  }

  getVideoIframe() {
    var reqUrl = `${this.channelId}/videos/${this.videoDetail.vimeoVideoId}`;
    this.vimeoService.getVimeoVideoIframe(reqUrl).subscribe(
      (item) => {
        this.videoLoader = false;
        this.videoForLoad = this.sanitizer.bypassSecurityTrustHtml(
          item.embed.html
        );
      },
      (error) => {
        this.videoLoader = false;
        this.commonService.showToast('Something went wrong', 'warn', 3000);
      }
    );
  }

  watchVideo(video) {
    let queryParams: { [key: string]: any } = {};
    this.isVideosPaginated = true;
    this.videoParams = {
      page: 1,
      pageSize: 10,
      q: '',
      countryShortCode: this.router.url.includes('US') ? 'US' : 'UK',
    };
    if (this.videoDetail.id !== video.id) {
      if (this.deviceType !== 'Mobile') {
        this.renderer.setProperty(
          this.videoItemsContainer.nativeElement,
          'scrollTop',
          0
        );
      }
      if (this.categoryId) {
        queryParams['id'] = this.categoryId;
      }
      queryParams['videoId'] = video.id;
      this.mediaDetailsPath = this.isUserLoggedIn
        ? this.isStudent
          ? `student/admission/${
              this.router.url.includes('US') ? 'US' : 'UK'
            }/media-details`
          : `organisation/app-shell/media-details/${
              this.router.url.includes('US') ? 'US' : 'UK'
            }`
        : `public/video/${this.router.url.includes('US') ? 'US' : 'UK'}`;
      this.router.navigate([this.mediaDetailsPath], {
        queryParams,
      });
    }
  }

  startQuiz(isNote: boolean) {
    this.commonService.show(QuizPopupComponent, {
      data: {
        videoId: this.videoDetail.id, //data was not coming proper so sending video id
        videoNotes: this.videoDetail.notes,
        isDocument: isNote,
        questionsAndAswers: this.videoDetail.questions,
        baseURL: this.baseURL,
        videoDetail: this.videoDetail,
      },
      height: '90vh',
      width: window.innerWidth < 500 ? '100%' : '100vh',
      styleClass: 'leader-modal',
    });
  }

  onSearch(searchText: string) {
    return this.commonService.getList(this.publicOrPrivatePathForVideos, {
      page: 1,
      pageSize: 5,
      q: searchText,
    });
  }

  handleSearchResults(searchResults: any) {
    console.log(searchResults);
    this.videoList = searchResults.data.videos;
  }

  loadMoreData() {
    if (this.isVideosPaginated) {
      this.videoParams.page++;
      this.commonService
        .getList(this.publicOrPrivatePathForVideos, this.videoParams)
        .subscribe(
          (res) => {
            if (res.data.videos.length == 0) {
              this.isVideosPaginated = false;
            }
            console.log(res.total);
            this.videoList.push(...res.data.videos);
          },
          (error) => {
            this.commonService.showToast(
              error.error?.error ?? 'Something went wrong',
              'warn',
              3000
            );
          }
        );
    }
  }

  ngOnDestroy() {
    if (this.ref) {
      this.ref.close();
    }
  }
}
