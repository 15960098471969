<div class="d-flex h-100 overflow-hidden flex-column">
  <div class="py-3 position-relative z-1">
    <div class="container d-flex flex-wrap align-items-center gap-2 gap-md-4">
      <!-- Dropdown Business Case Competition -->
      <div class="flex-grow-1">
        <div class="p-inputgroup search-inputgroup custom-dropdown">
          <input
            type="text"
            [(ngModel)]="searchText"
            (ngModelChange)="filteredCompetitions()"
            pInputText
            placeholder="Search competitions"
          />
          <span class="search-icon-with-dropdown">
            <i class="pi pi-search"></i>
          </span>
        </div>
      </div>
      <div class="flex-shrink-0" *ngIf="isLoggedInUser">
        <button
          type="button"
          class="btn btn-gray-secondary d-flex gap-2 align-items-center gap-2 h-100 w-100"
          (click)="openJoinCompetition(true)"
        >
          <i style="color: #0cbd80" class="icon-trophy"></i>
          <span>My Competitions</span>
        </button>
      </div>
    </div>
  </div>

  <div class="flex-grow-1 overflow-auto" [ngClass]="{ 'pb-4': isLoggedInUser }">
    <div class="container">
      <div
        class="mt-2 mb-4 d-flex align-items-center gap-3 justify-content-between flex-wrap"
      >
        <div
          class="flex-grow-1 d-flex justify-content-between align-items-center gap-3"
        >
          <h6 class="m-0 competition-heading">Competitions</h6>

          <!-- share page button for mobile view  -->
          <div class="d-lg-none ms-auto">
            <button
              class="btn btn-page-share"
              type="button"
              (click)="openSharePage($event)"
            >
              <span>Share</span>
              <span
                ><svg
                  width="15"
                  height="13"
                  viewBox="0 0 15 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.08333 4.12565V0.958984L14.625 6.50065L9.08333 12.0423V8.79648C5.125 8.79648 2.35417 10.0632 0.375 12.834C1.16667 8.87565 3.54167 4.91732 9.08333 4.12565Z"
                    fill="#3247C6"
                  />
                </svg>
              </span>
            </button>
          </div>
          <!-- End: share page button for mobile view  -->
        </div>
      </div>

      <!-- My competitions card list & non logged in subscribe card  -->
      <div
        class="d-flex flex-column-reverse flex-lg-row co-detail-card-wrapper"
      >
        <app-co-detail-card
          *ngIf="clubbedCompetitionsList?.length > 0"
          class="flex-grow-1"
          [isRegisterationOpen]="isRegisterationOpen"
          [competitions]="clubbedCompetitionsList"
          [myCompetition]="myCompetition"
          [ngClass]="{ 'public-competition-card-wrapper': !isLoggedInUser }"
        ></app-co-detail-card>
        <ng-container *ngIf="!clubbedCompetitionsList?.length">
          <!-- No Competition  -->
          <div
            class="dashboard-competitions-card card d-flex flex-column justify-content-center align-items-center flex-grow-1 gap-3 p-4"
          >
            <figure class="m-0">
              <img
                src="../../assets/competitions/trophy2x.png"
                alt="Competition Trophy Image"
              />
            </figure>
            <h5
              class="join-competition-text text-center text-decoration-none mb-0"
            >
              No competition found!
            </h5>
          </div>
          <!-- End: No Competition  -->
        </ng-container>
        <!-- Newest updates card -->
        <div class="newest-updates-card-wrapper">
          <div
            class="card newest-updates-card"
            [ngStyle]="{ top: '0px' }"
            *ngIf="!isLoggedInUser"
          >
            <div class="card-body d-flex flex-column gap-3">
              <div class="card-title">
                Stay updated on the newest competitions & research opportunities!
              </div>

              <form
                [formGroup]="subscriptionForm"
                class="d-flex flex-column gap-3"
              >
                <!-- <div>
                  <input
                    formControlName="fullName"
                    type="text"
                    class="p-inputtext-md"
                    placeholder="Enter your name"
                    pInputText
                  />
                </div> -->
                <div>
                  <input
                    formControlName="email"
                    type="email"
                    class="p-inputtext-md"
                    placeholder="Enter your email"
                    pInputText
                  />
                </div>
                <div>
                  <button
                    class="btn btn-primary w-100 rounded fw-bold"
                    (click)="subscribeToCompetition()"
                  >
                    Subscribe
                  </button>
                </div>
              </form>
            </div>
          </div>

          <!-- subscribe for logged in users  -->
          <div class="btn-subscribe-wrapper" *ngIf="isLoggedInUser">
            <div class="subscribe-card">
              <div class="card-title">
                Stay updated on the newest competitions & research opportunities!
              </div>
              <div class="w-100">
                <button
                  class="btn btn-primary btn-subscribe rounded fw-bold"
                  [disabled]="isSubscription && isLoggedInUser"
                  (click)="subscribeToCompetition()"
                >
                  {{
                    isSubscription && isLoggedInUser
                      ? "Subscribed"
                      : "Subscribe"
                  }}
                </button>
              </div>
            </div>
          </div>
          <!-- End: subscribe for logged in users -->

          <!-- Share This Page component -->
          <app-share-page-card
            class="d-none d-lg-block"
            [ngStyle]="{ top: isLoggedInUser ? '195px' : '237px' }"
          ></app-share-page-card>
          <!-- End: Share This Page component -->
        </div>
      </div>
    </div>
  </div>
</div>

<app-my-competitions
  [myCompetition]="myCompetition"
  (isCLosedClicked)="closeJoinCompetition($event)"
  *ngIf="isJoinCompetitionCanvasOpen"
></app-my-competitions>

<!-- Share Page Items for Mobile View -->
<app-share-page-card
  *ngIf="isSharePageOpen"
  [isSharePageOpen]="isSharePageOpen"
  (closeShareModal)="closeSharePage($event)"
></app-share-page-card>
<!-- End: Share Page Items for Mobile View -->
