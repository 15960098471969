import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommonServiceService } from '../../common-service.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-share-page-card',
  templateUrl: './share-page-card.component.html',
  styleUrls: ['./share-page-card.component.scss'],
})
export class SharePageCardComponent {
  @HostListener('window:resize', ['$event']) checkDeviceType(): void {
    const isMobile = window.innerWidth <= 991;
    this.deviceType = isMobile ? 'Mobile' : 'Desktop';
    if (this.deviceType === 'Mobile') {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }
  @Input() isSharePageOpen: boolean;
  @Output() closeShareModal = new EventEmitter<boolean>();
  shareIcons = [
    {
      src: '../../../../assets/share-icons/fb.png',
      alt: 'Facebook',
      content: 'Share on Facebook',
      id: 1,
    },
    {
      src: '../../../../assets/share-icons/twitter.png',
      alt: 'Twitter',
      content: 'Share on X',
      id: 2,
    },
    {
      src: '../../../../assets/share-icons/linkedin.png',
      alt: 'LinkedIn',
      content: 'Share on Linkedin',
      id: 3,
    },
    {
      src: '../../../../assets/share-icons/whatsapp.png',
      alt: 'WhatsApp',
      content: 'Share via whatsapp',
      id: 4,
    },
    {
      src: '../../../../assets/share-icons/snapchat.png',
      alt: 'Snapchat',
      content: 'Share on snapchat',
      id: 5,
    },
    {
      src: '../../../../assets/share-icons/gmail.png',
      alt: 'Gmail',
      content: 'Share via Email',
      id: 6,
    },
  ];
  deviceType: string;
  isMobileView: boolean;
  shareLink: string;

  constructor(
    private commonService: CommonServiceService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.checkDeviceType();
    this.shareLink = `${environment.openShareLink}explore-competitions`;
  }

  closeSharePage(event): void {
    this.closeShareModal.emit(event);
  }

  handleButtonClick(buttonId: number): void {
    switch (buttonId) {
      case 1:
        // Facebook
        const facebookLink =
          'https://www.facebook.com/sharer/sharer.php?u=' +
          encodeURIComponent(this.shareLink);
        window.open(facebookLink, '_blank');
        break;
      case 2:
        // Twitter
        const twitterLink =
          'https://twitter.com/intent/tweet?url=' +
          encodeURIComponent(this.shareLink);
        window.open(twitterLink, '_blank');
        break;
      case 3:
        // LinkedIn
        const linkedinLink =
          'https://www.linkedin.com/sharing/share-offsite/?url=' +
          encodeURIComponent(this.shareLink);
        window.open(linkedinLink, '_blank');
        break;
      case 4:
        // Whatsapp
        const whatsappLink =
          'https://api.whatsapp.com/send?text=' +
          encodeURIComponent(this.shareLink);
        window.open(whatsappLink, '_blank');
        break;
      case 5:
        // Whatsapp
        const snapchatLink =
          'https://www.snapchat.com/add/' + encodeURIComponent(this.shareLink);
        window.open(snapchatLink, '_blank');
        break;
      case 6:
        // Gmail
        const gmailLink =
          'https://mail.google.com/mail/?view=cm&fs=1&su=' +
          encodeURIComponent('Subject') +
          '&body=' +
          encodeURIComponent(this.shareLink);
        window.open(gmailLink, '_blank');
        break;
      case 100:
        // Copy Link
        this.copyToClipboard();
        break;
      // Add other cases for additional buttons
    }
  }

  copyToClipboard() {
    navigator.clipboard.writeText(this.shareLink);
    this.commonService.showToast('Link Copied', 'success', 1000);
  }
}
