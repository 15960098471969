import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// import { UkAdmisssionComponent } from './website/uk-admisssion/uk-admisssion.component';
// import { UsAdmisssionComponent } from './website/us-admisssion/us-admisssion.component';
// import { HomePageComponent  } from './website/home-page/home-page.component';
import { LoginComponent } from './admin/login/login.component';
import { AuthGuard } from './shared/auth.guard';
import { NotFoundPageComponent } from './shared/components/not-found-page/not-found-page.component';
import { PaymentResultComponent } from './shared/components/payment-result/payment-result.component';
import { PublicForumComponent } from './shared/components/public-forum/public-forum.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { ExploreCompetitionsComponent } from './explore-competitions/explore-competitions.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'student',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: SignInComponent,
  },
  {
    path: 'competition',
    component: SignInComponent,
  },
  {
    path: 'acceptteaminvite',
    component: SignInComponent,
  },
  {
    path: 'acceptinvite',
    component: SignInComponent,
  },
  {
    path: 'org/acceptinvite',
    component: SignInComponent,
  },
  {
    path: 'student/reset',
    component: SignInComponent,
    title: 'AlgoEd Student | Reset Password',
  },
  {
    path: 'organization/reset',
    component: LoginComponent,
    data: {
      title: "Organisation's Admin \n Reset Password",
      id: 2,
    },
  },
  {
    path: 'signup',
    component: SignUpComponent,
  },
  {
    path: 'admin-login',
    component: LoginComponent,
    data: {
      title: 'Admin Login',
      id: 1,
    },
  },
  {
    path: 'organisation-admin-login',
    component: LoginComponent,
    data: {
      title: 'Organisation Login',
      id: 2,
    },
  },
  {
    path: 'signup/:stepId',
    component: SignUpComponent,
  },
  {
    path: 'forum',
    component: PublicForumComponent,
  },
  {
    path: 'return',
    component: PaymentResultComponent,
  },
  // {
  //   path: 'parentPayment',
  //   component: PaymentResultComponent,
  // },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
    data: {
      title: 'Admin Login',
      id: 1,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'public',
    loadChildren: () =>
      import('./public-pages/public-pages.module').then(
        (m) => m.PublicPagesModule
      ),
  },
  {
    path: 'organisation',
    loadChildren: () =>
      import('./organisation/organisation.module').then(
        (m) => m.OrganisationModule
      ),
    canActivate: [AuthGuard],
    data: {
      title: 'Organisation Login',
      id: 2,
    },
  },
  {
    path: 'student',
    loadChildren: () =>
      import('./students/students.module').then((m) => m.StudentsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'explore-competitions',
    component: ExploreCompetitionsComponent,
  },
  {
    path: '**',
    component: NotFoundPageComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      bindToComponentInputs: true,
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
