import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-custom-file-upload',
  templateUrl: './custom-file-upload.component.html',
  styleUrls: ['./custom-file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomFileUploadComponent),
      multi: true,
    },
  ],
})
export class CustomFileUploadComponent implements ControlValueAccessor {
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  @Input() imageUrl: string | ArrayBuffer | null = null;
  @Input() isButtonsShown: boolean;

  @Input() height: number = 145;
  @Input() width: number = 250;
  @Input() defaultPlaceholder: string = 'assets/drag-n-drop.svg';

  @Output() removeFile: EventEmitter<boolean> = new EventEmitter<boolean>();

  onChange: any = () => {};
  onTouched: any = () => {};
  inputId = '';

  ngOnInit(): void {
    this.inputId = `file-input-${Math.floor(Math.random() * 1000000)}`;
  }

  writeValue(obj: any): void {
    this.imageUrl = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // this.isButtonsShown = !isDisabled;
  }

  onFileSelected(event: Event): void {
    const file = (event.target as HTMLInputElement).files![0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imageUrl = reader.result;
        this.onChange(file);
        this.onTouched();
      };
      reader.readAsDataURL(file);
    }
  }

  openFileSelector(): void {
    this.fileInput.nativeElement.click();
  }

  removeImage(): void {
    console.log(this.imageUrl);
    this.imageUrl = null;
    this.removeFile.emit(true);
    this.onChange(this.imageUrl);
  }
}
