<p-menubar [model]="items" [autoDisplay]="false">
  <ng-template pTemplate="start">
    <div class="d-flex align-items-center">
      <div class="d-flex align-items-center">
        <img
          src="../../../assets/icons/logo-2.svg"
          class="pointer-cursor"
          alt=""
          srcset=""
          (click)="handleLogoNav()"
        />
        <label
          *ngIf="!isMobileView"
          class="menu-bar-label d-flex cursor-pointer"
          for="primarySidebar"
        >
          <span class="pi pi-bars" *ngIf="isStudentProfile"></span>
        </label>
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="end">
    <label
      class="menu-bar-label d-flex cursor-pointer"
      for="primarySidebar"
      *ngIf="isMobileView"
    >
      <span class="pi pi-bars" *ngIf="isStudentProfile"></span>
    </label>
    <div
      class="d-flex align-items-center gap-3 action-menu-nav"
      *ngIf="!isMobileView"
    ></div>
  </ng-template>
</p-menubar>
