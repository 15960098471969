import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CommonServiceService } from '../shared/common-service.service';
import { Competitions } from '../student-competitions/models/competions.models';
import { catchError, from, map, mergeMap, of, toArray } from 'rxjs';

@Component({
  selector: 'app-explore-competitions',
  templateUrl: './explore-competitions.component.html',
  styleUrls: ['./explore-competitions.component.scss'],
})
export class ExploreCompetitionsComponent implements OnInit {
  @HostListener('window:resize', ['$event']) checkDeviceType(): void {
    const isMobile = window.innerWidth <= 991;
    this.deviceType = isMobile ? 'Mobile' : 'Desktop';
    if (this.deviceType === 'Mobile') {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }
  onResize(event: Event): void {
    this.isCheckboxChecked = false;
  }

  public deviceType: string;
  public isMobileView: boolean = false;
  public searchText: string;
  public tempCompetitions: Competitions[] = [];
  public isLoggedInUser: boolean;
  public isCheckboxChecked = false;
  public isSharePageOpen = false;
  public subscriptionForm: FormGroup;
  public subscriptionFormData: any;
  public clubbedCompetitionsList: any;

  constructor(
    private commonService: CommonServiceService,
    private route: Router,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    // this.commonService.logout();
    localStorage.removeItem('competitionIdForStudent');
    this.isLoggedInUser =
      (localStorage.getItem('user') &&
        JSON.parse(localStorage.getItem('user'))) ||
      false;
    if (this.isLoggedInUser) {
      this.route.navigate(['/student/competitions/startup']);
    } else {
      this.checkDeviceType();
      this.getCompetitions();
    }
    this.subscriptionForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  getCompetitions() {
    const competitions$ = this.commonService
      .getList('users/competitions/public', {})
      .pipe(
        map(
          (res) =>
            (res.data &&
              res.data.competitions.filter(
                (x: Competitions) => x.registration_stage !== 'Closed'
              )) ||
            []
        ),
        catchError((error) => {
          this.commonService.showToast(
            'Something went wrong with competitions',
            'error',
            3000
          );
          return of([]); // Return an empty array in case of error
        })
      );

    const upcomingCompetitions$ = this.commonService
      .getList('users/upcoming/competitions')
      .pipe(
        map((res) =>
          res.data.map((item: any) => ({ ...item, isUpcoming: true }))
        ),
        catchError((error) => {
          this.commonService.showToast(
            'Something went wrong with upcoming competitions',
            'error',
            3000
          );
          return of([]); // Return an empty array in case of error
        })
      );

    from([competitions$, upcomingCompetitions$])
      .pipe(
        mergeMap((obs) => obs),
        toArray()
      )
      .subscribe({
        next: ([competitions, upcomingCompetitions]) => {
          this.clubbedCompetitionsList = [
            ...competitions,
            ...upcomingCompetitions,
          ];
          // Sorting upcoming competitions at the end
          this.clubbedCompetitionsList.sort((a: any, b: any) => {
            if (a.isUpcoming && !b.isUpcoming) return 1;
            if (!a.isUpcoming && b.isUpcoming) return -1;
            return 0;
          })?.filter((x) => x.status !== 'Finished');;
          this.tempCompetitions = [...this.clubbedCompetitionsList];
        },
        error: (error) => {
          this.commonService.showToast(
            'Something went wrong with the request',
            'error',
            3000
          );
        },
      });
  }

  filteredCompetitions() {
    if (this.searchText) {
      this.clubbedCompetitionsList = this.tempCompetitions.filter(
        (competition) =>
          competition.name.toLowerCase().includes(this.searchText.toLowerCase())
      );
    } else {
      this.clubbedCompetitionsList = [...this.tempCompetitions];
    }
  }

  openSharePage(event: Event): void {
    if (navigator.share) {
      navigator
        .share({
          title: 'AlgoEd | Explore Competitions',
          text: 'AlgoEd is a platform that hosts curated, prestigious competitions for middle and high school students.',
          url: `${environment.openShareLink}explore-competitions`,
        })
        .catch((err) => {
          console.error('Error sharing:', err);
        });
    } else {
      this.isSharePageOpen = !this.isSharePageOpen;
    }
  }

  closeSharePage(event): void {
    this.isSharePageOpen = event;
  }

  subscribeToCompetition() {
    let finalObj = {};
    if (!this.isLoggedInUser) {
      if (this.subscriptionForm.valid) {
        const formData = this.subscriptionForm.value;

        this.subscriptionFormData = {
          email: formData.email,
        };
        finalObj = {
          isCategorList: true,
          name: this.subscriptionFormData,
        };
      } else {
        this.commonService.validateAndShowErrorMessage(this.subscriptionForm, {
          name: 'Email id',
          controlName: 'email',
        });
        if (this.subscriptionForm.controls['email'].errors['email']) {
          this.commonService.showToast(
            'Please enter valid email id',
            'error',
            3000
          );
        }
        return;
      }
    }
    this.commonService
      .saveRecord('users/competitions/subscribe', finalObj)
      .subscribe(
        (res) => {
          if (res) {
            this.subscriptionForm.reset();
            this.commonService.showToast(
              'Subscribed to competition successfully',
              'success',
              3000
            );
          }
        },
        (error) => {
          this.commonService.showToast(error?.error?.error, 'error', 3000);
        }
      );
  }

  closeSidebar() {
    this.isCheckboxChecked = !this.isCheckboxChecked;
  }
}
