import { Component, EventEmitter, HostListener, Output } from '@angular/core';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { Competitions } from '../../models/competions.models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { catchError, from, map, mergeMap, of, toArray } from 'rxjs';

@Component({
  selector: 'app-startup',
  templateUrl: './startup.component.html',
  styleUrls: ['./startup.component.scss'],
})
export class StartupComponent {
  isMobileView: boolean;
  deviceType: string;
  @HostListener('window:resize', ['$event']) checkDeviceType(): void {
    const isMobile = window.innerWidth <= 768;
    this.deviceType = isMobile ? 'Mobile' : 'Desktop';
    if (this.deviceType === 'Mobile') {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }
  isSharePageOpen = false;
  window = window;
  searchText: string;
  competitions: Competitions[] = [];
  tempCompetitions: Competitions[] = [];
  myCompetition: Competitions[] = [];
  isRegisterationOpen: boolean = true;
  isDropdownOpen: boolean;
  apiPath: string;
  isLoggedInUser: boolean;
  subscriptionForm: FormGroup;
  subscriptionFormData: any;
  public isJoinCompetitionCanvasOpen: boolean = false;
  isSubscription: boolean;
  clubbedCompetitionsList: any[];

  constructor(
    private commonService: CommonServiceService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.commonService.isBackFromTeamDetails.next(false);
    this.isLoggedInUser =
      (localStorage.getItem('user') &&
        JSON.parse(localStorage.getItem('user'))) ||
      false;

    this.isLoggedInUser && this.checkSubscriptionStatus();
    this.checkDeviceType();
    this.getCompetitions();

    this.subscriptionForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  checkSubscriptionStatus() {
    this.commonService.getList(`users/competitions/subscribe`).subscribe(
      (res) => {
        if (res?.subscibed) {
          this.isSubscription = true;
        }
      },
      (error) => {
        this.commonService.showToast('Something went wrong', 'error', 3000);
      }
    );
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  filteredCompetitions() {
    if (this.searchText) {
      this.clubbedCompetitionsList =
        this.tempCompetitions &&
        this.tempCompetitions.filter((competition) =>
          competition.name.toLowerCase().includes(this.searchText.toLowerCase())
        );
    } else {
      this.clubbedCompetitionsList = [...this.tempCompetitions];
    }
  }

  getCompetitions() {
    const competionApiPath = this.isLoggedInUser
      ? 'users/competitions'
      : 'users/competitions/public';

    const competitions$ = this.commonService.getList(competionApiPath, {}).pipe(
      map((res) => {
        this.myCompetition = (res.data && res.data.mycompetitions) || [];
        this.myCompetition = this.myCompetition.map((x) => {
          return {
            ...x,
            isPendingActionsCompleted:
              ((x.individual == 0
                ? x.required_team_members - x.member_count <= 0
                : true) &&
                (x.individual == 0
                  ? x.member_paid_count == x.member_count
                  : true) &&
                x.payment_status === 'Paid') ||
              x.payment_status === 'Waived',
          };
        });

        if (!this.isLoggedInUser) {
          const competitions =
            (res.data &&
              res.data.competitions.filter(
                (x: Competitions) =>
                  x.registration_stage !== 'Closed' || x.status !== 'Finished'
              )) ||
            [];
          return competitions;
        } else {
          return (res.data && res.data.competitions) || [];
        }
      }),
      catchError((error) => {
        this.commonService.showToast(
          'Something went wrong with competitions',
          'error',
          3000
        );
        return of([]); // Return an empty array in case of error
      })
    );

    const upcomingCompetitions$ = this.commonService
      .getList('users/upcoming/competitions')
      .pipe(
        map((res) =>
          res.data.map((item: any) => ({ ...item, isUpcoming: true }))
        ),
        catchError((error) => {
          this.commonService.showToast(
            'Something went wrong with upcoming competitions',
            'error',
            3000
          );
          return of([]); // Return an empty array in case of error
        })
      );

    from([competitions$, upcomingCompetitions$])
      .pipe(
        mergeMap((obs) => obs),
        toArray()
      )
      .subscribe({
        next: ([competitions, upcomingCompetitions]) => {
          this.clubbedCompetitionsList = [
            ...competitions,
            ...upcomingCompetitions,
          ]?.filter((x) => x.status !== 'Finished');

          // Sorting logic
          this.clubbedCompetitionsList.sort((a: any, b: any) => {
            const now = new Date();

            // 1. Current competitions in registration state with closest competition start first
            const isRegistrationA =
              a.registration_stage !== 'Closed' &&
              new Date(a.competition_start) >= now;
            const isRegistrationB =
              b.registration_stage !== 'Closed' &&
              new Date(b.competition_start) >= now;

            if (isRegistrationA && !isRegistrationB) return -1;
            if (!isRegistrationA && isRegistrationB) return 1;

            if (isRegistrationA && isRegistrationB) {
              return (
                new Date(a.competition_start).getTime() -
                new Date(b.competition_start).getTime()
              );
            }

            // 2. Upcoming competitions
            if (a.isUpcoming && !b.isUpcoming) return -1;
            if (!a.isUpcoming && b.isUpcoming) return 1;

            // 3. In-progress competitions
            if (a.status === 'In Progress' && b.status !== 'In Progress')
              return -1;
            if (a.status !== 'In Progress' && b.status === 'In Progress')
              return 1;

            // 4. Competitions where isJoined = true should be last
            if (a.isJoined && !b.isJoined) return 1;
            if (!a.isJoined && b.isJoined) return -1;
            return 0;
          });
          this.tempCompetitions = [...this.clubbedCompetitionsList];
        },
        error: (error) => {
          this.commonService.showToast(
            'Something went wrong with the request',
            'error',
            3000
          );
        },
      });
  }

  openJoinCompetition(isJoinClicked: boolean) {
    this.isJoinCompetitionCanvasOpen = isJoinClicked;
  }
  closeJoinCompetition(isCloseClicked: boolean) {
    this.isJoinCompetitionCanvasOpen = isCloseClicked;
  }

  subscribeToCompetition() {
    let finalObj = {};
    if (!this.isLoggedInUser) {
      if (this.subscriptionForm.valid) {
        const formData = this.subscriptionForm.value;

        this.subscriptionFormData = {
          email: formData.email,
        };
        finalObj = {
          isCategorList: true,
          name: this.subscriptionFormData,
        };
      } else {
        this.commonService.validateAndShowErrorMessage(this.subscriptionForm, {
          name: 'Email id',
          controlName: 'email',
        });
        if (this.subscriptionForm.controls['email'].errors['email']) {
          this.commonService.showToast(
            'Please enter valid email id',
            'error',
            3000
          );
        }
        return;
      }
    }
    this.commonService
      .saveRecord(
        'users/competitions/subscribe',
        this.isLoggedInUser ? {} : finalObj
      )
      .subscribe(
        (res) => {
          if (res) {
            this.subscriptionForm.reset();
            this.commonService.showToast(
              'Subscribed to competition successfully',
              'success',
              3000
            );
            this.isLoggedInUser && this.checkSubscriptionStatus();
          }
        },
        (error) => {
          this.commonService.showToast(error?.error?.error, 'error', 3000);
        }
      );
  }

  openSharePage(event: Event): void {
    if (navigator.share) {
      navigator
        .share({
          title: 'AlgoEd Student | Competitions',
          url: `${environment.openShareLink}explore-competitions`,
        })
        .catch((err) => {
          console.error('Error sharing:', err);
        });
    } else {
      this.isSharePageOpen = !this.isSharePageOpen;
    }
  }

  closeSharePage(event): void {
    this.isSharePageOpen = event;
  }
}
