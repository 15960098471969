import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router, RouterLinkActive } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonServiceService } from 'src/app/shared/common-service.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {
  // View-related decorator
  @ViewChild('closeButton', { static: false }) closeButton: ElementRef;

  // HostListener method
  @HostListener('window:resize', ['$event'])
  checkDeviceType(): void {
    const isMobile = window.innerWidth <= 768;
    this.deviceType = isMobile ? 'Mobile' : 'Desktop';
    if (this.deviceType === 'Mobile') {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  // Input decorators
  @Input() selectedCountry: string;
  @Input() items: any;
  @Input() isCountryToggleButton: boolean = true;
  @Input() isSideMenuShown: boolean;
  @Input() isCustomIcons: boolean = true;

  // Output decorators
  @Output() onCountryChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() triggerCloseSidebar: EventEmitter<any> = new EventEmitter<any>();

  // Array type
  navItems = [];

  // Other types
  sidemenu: any;
  currentPath: any;

  // Subscription type
  sub: Subscription;

  // Boolean types
  isBasicPlan: boolean = false;
  selectedLanguage: any = false;
  isUK: boolean = false;
  isMobileView: boolean;

  // String type
  deviceType: string;

  // Object type
  userData: any;

  // Boolean type
  isLoggedIn: boolean;

  isOrganisationInsiderStartegy: boolean;

  // Add a blank line to separate different types

  constructor(
    private router: Router,
    private commonService: CommonServiceService
  ) {
    this.commonService.$curUser.subscribe((item2) => {
      if (typeof item2 === 'string') {
        this.userData = JSON.parse(item2);
      } else {
        this.userData = item2;
      }
      this.checkDeviceType();
      this.navItems = [
        {
          label: 'Personal Info',
          icon: 'icon icon-user-edit',
          routerLink: '/student/personal-information',
          RouterLinkActive,
        },
        {
          label: 'Student Profile',
          id: 'studentProfileSubMenu',
          styleClass: 'stopHover',
          icon: 'icon icon-user-square',
          routerLink: '/student/student-profile/personality-test',
        },
        // {
        //   label: 'Billing',
        //   routerLink: '/student/billing',
        //   icon: 'icon icon-dollar-square',
        //   RouterLinkActive,
        // },
        {
          label: 'Change Password',
          routerLink: '/student/change-password',
          icon: 'pi pi-fw pi-user',
          RouterLinkActive,
        },
      ];
    });
  }

  ngOnChanges() {
    if (!this.isCountryToggleButton) {
      this.navItems = [];
    }
  }

  closeSidebar() {
    if (this.closeButton) {
      this.closeButton.nativeElement.click();
    }
  }

  logout() {
    this.commonService.logout();
    if (
      !this.router.url.includes('organisation') &&
      !this.router.url.includes('admin')
    ) {
      this.router.navigate(['/login']);
    } else if (this.router.url.includes('app-shell')) {
      this.router.navigate(['/organisation-admin-login']);
    } else {
      this.router.navigate(['/admin-login']);
    }
  }

  ngOnInit(): void {
    this.isUK = this.router.url.includes('/admission/UK');
    this.router.events.subscribe((item) => {
      if (item instanceof NavigationEnd) {
        this.isOrganisationInsiderStartegy =
          this.router.url == '/organisation/app-shell/US' ||
          this.router.url == '/organisation/app-shell/UK'
            ? true
            : false;
      }
    });
    this.commonService.$selectedContry.next(this.isUK ? 'UK' : 'US');
  }

  toggleLanguage() {
    this.isUK = !this.isUK;
    let currentUrl = this.router.url;
    if (this.isOrganisationInsiderStartegy) {
      let newUrl = currentUrl.replace(
        /(app-shell\/)(US|UK)/,
        `app-shell/${this.isUK ? 'UK' : 'US'}`
      );

      this.router.navigateByUrl(newUrl);
      this.commonService.$selectedContry.next(this.isUK ? 'UK' : 'US');
    } else {
      let newUrl = currentUrl.replace(
        /(admission\/)(US|UK)/,
        `admission/${this.isUK ? 'UK' : 'US'}`
      );
      this.router.navigateByUrl(newUrl);
      this.commonService.$selectedContry.next(this.isUK ? 'UK' : 'US');
    }
  }

  toggleSubMenu(item: any) {
    item.expanded = !item.expanded;
  }
}
