import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CommonServiceService } from './common-service.service';
import { DecryptService } from './decrypt.service';

@Injectable({
  providedIn: 'root',
})
export class JwtService {
  notAllowedPaths = [
    'submitEssay',
    'submitActivity',
    'MarkEssaySubmissionAsCompleted',
    'MarkActivitySubmissionAsCompleted',
    'fileImport',
    'uploadVideoToGetLocalPath',
    'uploadVideoNotes',
    'addProfileAcademicRecord',
    'changeProfileAcademicRecord',
    'addUpdateProfileAcademicInterest',
    'addRequestAcademicRecord',
    'addRequestAcademicInterest',
    'submitOtherSubmission',
    'MarkOtherSubmissionAsCompleted',
    'addUpdateRequestFormType',
  ];

  constructor(
    private router: Router,
    private commonService: CommonServiceService,
    private encdec: DecryptService
  ) {}
  token: string = '';

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // let token = JSON.parse(localStorage.getItem("token"));
    this.commonService.$curAccessToken.subscribe((item) => {
      this.token = item;
    });
    const adminToken = localStorage.getItem('adToken');
    if (adminToken) {
      this.token = adminToken;
    }

    const loggedInUser = localStorage.getItem('token');
    if (loggedInUser) {
      this.token = loggedInUser;
    }

    let path = req.url.split('/');
    let encBody = undefined;

    if (
      !this.notAllowedPaths.includes(path[path.length - 1]) &&
      !req?.body?.isCategorList
    ) {
      encBody = this.encdec.encrypt(req.body);
    }

    if (req?.body?.isCategorList) {
      delete req.body.isCategorList;
      encBody = this.getFirstKey(req.body);
    }
    console.log(
      'Decrpted Data is \n encBody',
      req.url,
      '\n',
      encBody?.data ? JSON.parse(this.encdec.decrypt(encBody?.data)) : ''
    );
    let curToken;
    if (this.token) {
      curToken = this.token;
    } else {
      curToken = '';
    }
    if (
      (req.url.includes('api.vimeo.com') === false &&
        !req.url.includes('questions')) ||
      req.method == 'POST'
    ) {
      req = req.clone({
        setHeaders: {
          // token: `${this.token ?? ''}`
          token: curToken,
        },
        body: encBody ?? undefined,
      });
    }

    if (
      req.url.includes('questions/myquestions') ||
      req.url.includes('questions/tags') ||
      req.url.includes('questions/id') ||
      req.url.includes('admin/videos/') ||
      req.method == 'POST'
    ) {
      req = req.clone({
        setHeaders: {
          token: curToken,
        },
        body: encBody ?? undefined,
      });
    }
    if (req.url.includes('checkUserExistsWithInviteCode')) {
      req = req.clone({
        body: encBody ?? undefined,
      });
    }

    return next.handle(req).pipe(
      tap((event) => {}),
      catchError((error: HttpErrorResponse) => {
        if (error.status == 401 || error.message == 'Access denied') {
          localStorage.clear();
          localStorage.clear();
          this.commonService.$curUserToken.next(null);
          if (
            !this.router.url.includes('organisation') &&
            !this.router.url.includes('admin')
          ) {
            this.router.navigate(['/login']);
          } else if (this.router.url.includes('organisation')) {
            this.router.navigate(['/organisation-admin-login']);
          } else {
            this.router.navigate(['/admin-login']);
          }
        }
        return throwError(error);
      })
    );
  }
  getFirstKey(obj: any): string | undefined {
    const keys = Object.keys(obj);
    return keys.length > 0 ? obj[keys[0]] : undefined;
  }
}
