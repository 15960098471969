import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  BehaviorSubject,
  Subject,
  debounceTime,
  distinctUntilChanged,
  switchMap,
} from 'rxjs';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent {
  @HostBinding('class') class = 'w-100';
  @Input() placeholder: string = 'Search';
  @Input() searchFunction: (searchText: string) => any;
  @Input() searchMaxWidth: string;

  @Output() searchResults: EventEmitter<any> = new EventEmitter();

  searchText: string;
  searchTextChanges: Subject<string> = new Subject<string>();

  constructor() {
    this.searchTextChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        switchMap((searchText) => this.searchFunction(searchText))
      )
      .subscribe(
        (response) => {
          this.searchResults.emit(response);
        },
        (error) => {
          console.log(`Error: ${error}`);
        }
      );
  }

  onTextSearched() {
    // if (this.searchText) {
    this.searchTextChanges.next(this.searchText);
    // } else {
    //   this.searchResults.emit([]);
    // }
  }
}
